<template>
    <div class="wid1200 subject-detail-page">
        <bread-crumb :pN="pN" :childName="pageName"></bread-crumb>
        <div class="subject-detail">
            <div class="left-subject-detail">
                <div class="swiper-wrap">
                    <!-- <subject-banner :pageName="pageName" :bannerData="imgBannerData"></subject-banner> -->
                    <!-- <img v-if="imgBannerData && imgBannerData[0] && imgBannerData[0].titleImg" :src="imgBannerData[0].titleImg" alt="" /> -->
                    <img v-if="detailObj && detailObj.logo2" :src="detailObj.logo2" alt="" />
                    <div class="content-wrap">
                        <p v-if="detailObj && detailObj.memo2" v-html="detailObj.memo2">
                        </p>
                    </div>

                    <!-- <h3 class="small" v-if="detailObj && detailObj.logo2Name ">
                        <p >{{detailObj.logo2Name}}</p>
                    </h3> -->
                    <!-- <h3 class="small" v-if="imgBannerData && imgBannerData[0] && imgBannerData[0].title">
                        <p >{{imgBannerData[0].title}}</p>
                    </h3> -->
                </div>

                <div class="sort-wrap" v-if="detailObj && detailObj.childLibraryList && detailObj.childLibraryList.length>0">
                    <div class="com-title">
                        <div>
                            <h5>分类</h5>
                        </div>
                        <!-- <div>
                            <span>更多<img src="../assets/img/more.png" alt="" /></span>
                        </div> -->
                    </div>
                    <div class="sort">
                        <ul>
                            <li :class="[activeSort==item.id? 'active-sort': '']"
                                v-for="(item, ind) in detailObj.childLibraryList" :key="ind" @click="switchSort(item)">
                                {{item.name}}</li>
                        </ul>
                    </div>
                </div>

                <div class="article-wrap" v-if="articleData && articleData.total > 0">
                    <subject-article :articleData="articleData" :pageName="pageName" @pageChange="articleChange">
                    </subject-article>
                </div>
                <div class="book-wrap" v-if="bookData && bookData.total > 0">
                    <subject-book :bookData="bookData" :pageName="pageName" @pageChange="bookChange" />
                </div>

                <div class="official-account-wrap" v-if="publicData && publicData.length>0">
                    <div class="com-title">
                        <div>
                            <!-- <img src="../assets/img/icon3.png" alt="" style="width: 21px; height: 23px;" /> -->
                            <h5>他山之石</h5>
                        </div>
                        <!-- <div>
                        <span>更多<img src="../assets/img/more.png" alt="" /></span>
                    </div> -->
                    </div>
                    <ul class="official-account">
                        <li v-for="item in publicData" :key="item.id">
                            <div class="img-wrap"  @click="goNews(item)">
                                <img src="../assets/img/Group743.png" alt="" />
                            </div>
                            <div class="content-fficial">
                                <p @click="goNews(item)">{{item.title}}</p>
                                <div>
                                    <span>{{item.releaseDate}}</span><span v-if="item.origin">来源：{{item.origin}}</span>
                                </div>
                            </div>
                        </li>
                    </ul>

                    <div class="pagination-wrap">
                        <el-pagination @current-change="handleCurrentChange" :page-size="publicSize"
                            layout="total, prev, pager, next, jumper" :total="publicTotal">
                        </el-pagination>
                    </div>
                </div>
            </div>
            <!-- <div class="right-subject-detail">
                <div class="hot-subject-wrap">
                    <div class="com-title">
                        <div>
                            <img src="../assets/img/hoticon.png" alt="" />
                            <h5>热门专题</h5>
                        </div>
                        <div>
                        <span>更多<img src="../assets/img/more.png" alt="" /></span>
                    </div>
                    </div>
                    <ul class="hot-subject">
                        <li v-for="item in hotData" :key="item.id" @click="goDetail(item.id)">{{item.name}}</li>
                 
                    </ul>
                </div>
            </div> -->
        </div>

    </div>
</template>

<script>
import BreadCrumb from '../components/BreadCrumb'
import SubjectBook from '../components/SubjectBook'
import SubjectArticle from '../components/SubjectArticle'
import SubjectBanner from '../components/SubjectDetailBanner'

import img1 from '../assets/img/book.png'

export default {
    components: { BreadCrumb, SubjectBook, SubjectArticle, SubjectBanner },
    data() {
        return {
            pageName: '专题详情页',
            pN: '',
            detailObj: {},
            hotData: [],
            bannerData: [
                {
                    titleImg: img1,
                    title: '1文章'
                },
                {
                    titleImg: img1,
                    title: '2文章'
                },
                {
                    titleImg: img1,
                    title: '3文章'
                },
                {
                    titleImg: img1,
                    title: '4文章'
                }
            ],
            imgBannerData: [],
            publicData: [],
            articleData: {
                title: '文章',
                articleList: [
                    {
                        title: '井上圆了对近代中国佛教改革理论的影响——以“人间佛教”...',
                        author: '陆学艺',
                        book: '马克思主义哲学研究',
                        time: '2020-09-12'
                    },
                    {
                        title: '井上圆了对近代中国佛教改革理论的影响——以“人间佛教”...',
                        author: '陆学艺',
                        book: '马克思主义哲学研究',
                        time: '2020-09-12'
                    },
                    {
                        title: '井上圆了对近代中国佛教改革理论的影响——以“人间佛教”...',
                        author: '陆学艺',
                        book: '马克思主义哲学研究',
                        time: '2020-09-12'
                    },
                    {
                        title: '井上圆了对近代中国佛教改革理论的影响——以“人间佛教”...',
                        author: '陆学艺',
                        book: '马克思主义哲学研究',
                        time: '2020-09-12'
                    },
                    {
                        title: '井上圆了对近代中国佛教改革理论的影响——以“人间佛教”...',
                        author: '陆学艺',
                        book: '马克思主义哲学研究',
                        time: '2020-09-12'
                    },
                    {
                        title: '井上圆了对近代中国佛教改革理论的影响——以“人间佛教”...',
                        author: '陆学艺',
                        book: '马克思主义哲学研究',
                        time: '2020-09-12'
                    }
                ]
            },
            bookData: {
                title: '图书',
                bookList: [
                    {
                        img: img1,
                        title: '比较政治学研究'
                    },
                    {
                        img: img1,
                        title: '比较政治学研究'
                    },
                    {
                        img: img1,
                        title: '比较政治学研究'
                    },
                    {
                        img: img1,
                        title: '比较政治学研究'
                    },
                    {
                        img: img1,
                        title: '比较政治学研究'
                    },
                    {
                        img: img1,
                        title: '比较政治学研究'
                    },
                    {
                        img: img1,
                        title: '比较政治学研究'
                    },
                    {
                        img: img1,
                        title: '比较政治学研究'
                    }
                ]
            },
            publicTotal: 0,
            publicDataId: '',
            publicNum: 1,
            publicSize: 10,
            activeSort: 0
        }
    },
    watch: {
        $route: 'init'
    },
    methods: {
        init() {
            if (this.$route.query.pN) {
                this.pN = decodeURI(this.$route.query.pN)
            } else {
                this.pN = ''
            }
            this.getDetail()
            // this.getArticleBook(1, 'ARTICLE')
            // this.getArticleBook(1, 'BOOK')
            // this.getHotData()
        },
        // getChildList() {
        //     var _this = this
        //     this.$http({
        //         method: 'get',
        //         url: '/admin/api/library/list?parentId=' + this.$route.query.id
        //     }).then(res => {
        //         if (res.data.code == 0) {
        //             // var x = res.data.data.rows
        //             }
        //     })
        // },
        switchSort(item) {
            if (this.activeSort == item.id) {
                return
            } else {
                this.activeSort = item.id
                this.getArticleBook(1, 'ARTICLE')
                this.getArticleBook(1, 'BOOK')
            }
        },
        handleCurrentChange(val) {
            // console.log('切换val', val)
            this.publicNum = val
            this.getNewsData(this.publicDataId)
            // this.$emit('pageChange', val)
        },
        goDetail(id) {
            this.$router.push({ path: '/subjectDetail/s', query: { id: id, pN: encodeURI(this.pageName) } })
        },
        goNews(item) {
            // this.$router.push({ path: '/nD/n', query: { id: id, title: title } })
            // this.$router.push({ path: '/aD/a', query: { id: id, title: title } })
            if (item.jumpUrl) {
                window.open(item.jumpUrl, '_blank')
            } else if (item.originUrl) {
                window.open(item.originUrl, '_blank')
            } else {
                this.$router.push({ path: '/nD/n', query: { id: item.id, pN: encodeURI(this.pageName) } })
                // title:item.title,
            }
        },

        articleChange(val) {
            this.getArticleBook(val, 'ARTICLE')
        },
        bookChange(val) {
            this.getArticleBook(val, 'BOOK')
        },
        getDetail(id) {
            var _this = this
            this.$http({
                method: 'get',
                url: '/admin/api/library/detail?id=' + this.$route.query.id
            }).then(res => {
                if (res.data.code == 0) {
                    _this.detailObj = res.data.data
                    _this.activeSort = _this.detailObj.childLibraryList[0].id
                    _this.getArticleBook(1, 'ARTICLE')
                    _this.getArticleBook(1, 'BOOK')
                    _this.pageName = res.data.data.name
                    _this.publicDataId = _this.detailObj.nameAlias + '_gzh'
                    _this.getNewsData(_this.detailObj.nameAlias + '_lbt')
                    _this.getNewsData(_this.detailObj.nameAlias + '_gzh')
                }
            })
        },
        getArticleBook(num, type) {
            var _this = this
            // http://jikan.a.com/admin/api/library/resource?libraryId=3&resourceTypeList=ARTICLE,BOOK&pageSize=1&pageNum=1&openAccessStatus=1
            var size
            var x
            if (type == 'ARTICLE') {
                size = 20
                x = {
                    libraryId: this.activeSort,
                    resourceType: type,
                    pageSize: size,
                    pageNum: num
                }
            } else if (type == 'BOOK') {
                size = 10
                x = {
                    libraryId: this.activeSort,
                    resourceTypeList: 'BOOK,WENJI', // "type 'WENJI'",
                    pageSize: size,
                    pageNum: num
                }
            }

            this.$http({
                method: 'get',
                url: '/admin/api/library/resource',
                params: x
            }).then(res => {
                if (res.data.code == 0) {
                    // _this.openData = res.data.data.rows
                    if (type == 'ARTICLE') {
                        _this.articleData = {}
                        _this.articleData.title = '文章'
                        _this.articleData.articleList = res.data.data.rows
                        _this.articleData.total = res.data.data.total
                        // console.log('article1', _this.articleData.articleList, 'article2', _this.articleData.total)
                    } else if (type == 'BOOK') {
                        _this.bookData = {}
                        _this.bookData.title = '图书'
                        _this.bookData.bookList = res.data.data.rows
                        _this.bookData.total = res.data.data.total
                        // console.log('book1', _this.bookData.bookList, 'book2', _this.bookData.total)
                    }
                }
            })
        },
        getNewsData(id) {
            var _this = this
            // console.log('id', id)
            if (id.slice(id.length - 3, id.length) == 'lbt') {
                //轮播图
                this.$http({
                    method: 'get',
                    url: '/admin/api/news/list',
                    params: {
                        innerCode: id,
                        pageNum: 1,
                        pageSize: 10
                    }
                }).then(res => {
                    _this.imgBannerData = res.data.data.rows
                })
            } else {
                //公众号
                this.$http({
                    method: 'get',
                    url: '/admin/api/news/list',
                    params: {
                        innerCode: _this.publicDataId,
                        pageNum: _this.publicNum,
                        pageSize: _this.publicSize
                    }
                }).then(res => {
                    _this.publicTotal = res.data.data.total
                    _this.publicData = res.data.data.rows
                })
            }
        },
        getHotData() {
            var _this = this
            this.$http({
                methods: 'get',
                url: '/admin/api/library/list?parentId=2',
                params: {
                    pageSize: 10,
                    pageNum: 1
                }
            }).then(res => {
                if (res.data.code == 0) {
                    _this.hotData = res.data.data.rows.slice(0, 10)
                }
            })
        }
    },
    created() {
        this.init()
    }
}
</script>

<style lang="scss" scoped>
.subject-detail-page {
    .subject-detail {
        display: flex;
        justify-content: space-between;

        .left-subject-detail {
            width: 1200px;
            .swiper-wrap {
                height: 510px;
                border: 1px solid #f5f5f5;
                box-shadow: 0px 5px 8px 1px rgba(0, 0, 0, 0.1);
                position: relative;
                img {
                    width: 1200px;
                    height: 510px;
                }
                .content-wrap {
                    // height: 112px;
                    width: 1200px;
                    position: absolute;
                    bottom: 21px;
                    left: 0;
                    // border: 1px solid #f5f5f5;
                    // box-shadow: 0px 5px 8px 1px rgba(0, 0, 0, 0.1);
                    // margin: 25px 0 25px;
                    box-sizing: border-box;
                    padding: 18px 23px;

                    background: #ffffff;
                    opacity: 0.89;
                    box-shadow: 0px 0px 4px 2px rgba(0, 0, 0, 0.1);

                    p {
                        font-size: 16px;
                        line-height: 26px;
                        // text-overflow: -o-ellipsis-lastline;
                        // overflow: hidden;
                        // text-overflow: ellipsis;
                        // display: -webkit-box;
                        // -webkit-line-clamp: 3;
                        // line-clamp: 3;
                        // -webkit-box-orient: vertical;
                    }
                }
                // h3 {
                //     position: absolute;
                //     bottom: 0;
                //     left: 0;
                //     width: 1200px;
                //     height: 50px;
                //     background: rgba(2, 2, 2, 0.5);
                //     p {
                //         width: 836px;
                //         height: 50px;
                //         line-height: 50px;
                //         overflow: hidden;
                //         text-overflow: ellipsis;
                //         white-space: nowrap;
                //         color: #fff;
                //         font-size: 22px;
                //         margin-left: 22px;
                //     }
                // }
            }
            .sort-wrap {
                border: 1px solid #f5f5f5;
                box-shadow: 0px 5px 8px 1px rgba(0, 0, 0, 0.1);
                padding: 10px 25px 40px;
                margin-bottom: 25px;
                margin-top: 28px;
                .com-title {
                    border-bottom: none;
                }
                .sort {
                    
                    ul {
                        display: flex;
                        flex-wrap: wrap;
                        li {
                            width: 200px;
                            height: 70px;
                            margin-right: 35px;
                            background: rgba(254, 232, 219, 1);
                            position: relative;
                            line-height: 70px;
                            text-align: center;
                            color: #666;
                            font-size: 22px;
                            cursor: pointer;
                            margin-top: 30px;
                        }
                        li.active-sort {
                            color: #fff;
                        }
                        li.active-sort:after {
                            content: '';
                            position: absolute;
                            bottom: -20px;
                            left: 50%;
                            transform: translateX(-50%);
                            width: 0;
                            height: 0;
                            border-left: 14px solid transparent;
                            border-right: 14px solid transparent;
                            border-top: 28px solid #bd1a2d;
                        }
                        li:nth-child(5n) {
                            margin-right: 0;
                        }
                        li.active-sort {
                            background: rgba(189, 26, 45, 1);
                        }
                    }
                }
            }

            .book-wrap {
                border: 1px solid #f5f5f5;
                box-shadow: 0px 5px 8px 1px rgba(0, 0, 0, 0.1);
                padding: 10px 25px;
                margin-bottom: 21px;
            }
            .article-wrap {
                border: 1px solid #f5f5f5;
                box-shadow: 0px 5px 8px 1px rgba(0, 0, 0, 0.1);
                padding: 10px 25px;
                margin-bottom: 21px;
            }
            .official-account-wrap {
                border: 1px solid #f5f5f5;
                box-shadow: 0px 5px 8px 1px rgba(0, 0, 0, 0.1);
                padding: 10px 30px;
                margin-bottom: 35px;
                .com-title {
                    border-bottom: none;
                }
                ul.official-account {
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: space-between;
                    margin-top: 20px;
                    li {
                        width: 540px;
                        // border-bottom: 1px solid #e6e6e6;
                        display: flex;
                        justify-content: space-between;
                        margin-bottom: 30px;
                        .img-wrap {
                            margin-right: 20px;
                            img {
                                width: 44px;
                                height: 44px;
                                cursor: pointer;
                            }

                        }
                        .content-fficial {
                            width: 477px;
                            p {
                                color: #333;
                                font-size: 16px;
                                font-weight: bold;
                                line-height: 16px;
                                width: 100%;
                                white-space: nowrap;
                                text-overflow: ellipsis;
                                overflow: hidden;
                                cursor: pointer;
                            }
                            div {
                                // display: flex;
                                // justify-content: space-between;
                                margin-top: 15px;
                                span {
                                    font-size: 14px;
                                    line-height: 14px;
                                    color: #999;
                                }
                                span:nth-child(1) {
                                    margin-right: 20px;
                                }
                            }
                        }
                     
                     
        
                    }
                    li:last-child {
                        border-bottom: none;
                    }
                }
            }
        }
        .right-subject-detail {
            .hot-subject-wrap {
                width: 316px;
                padding: 10px 20px 0;
                border: 1px solid #f5f5f5;
                box-shadow: 0px 5px 8px 1px rgba(0, 0, 0, 0.1);
                box-sizing: border-box;
                ul.hot-subject {
                    li {
                        height: 54px;
                        line-height: 54px;
                        border-bottom: 1px solid #dedede;
                        cursor: pointer;
                        font-size: 16px;
                        overflow: hidden;
                        white-space: nowrap;
                        text-overflow: ellipsis;
                    }
                    li:last-child {
                        border: none;
                    }
                }
            }
        }
    }
    .pagination-wrap {
        display: flex;
        justify-content: center;
        margin: 0px 0 10px;
    }
}
</style>