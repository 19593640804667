<template>
    <div class="com-book-component">
        <div class="com-title">
            <div>
                <h5>图书
                    <!-- {{bookData.title}} -->
                </h5>
            </div>
            <!-- <div>
                <span>更多<img src="../assets/img/more.png" alt="" /></span>
            </div> -->
        </div>
        <ul class="com-book">
            <li v-for="(item, index) in bookData.bookList" :key="index"
                @click="goDetail(item)">
                <img v-if="item.img" :src="item.img" alt="" />
                <img v-else alt="">
                <p><a :title="item.title">{{item.title}}</a></p>
            </li>
           
        </ul>
        <div class="pagination-wrap">
            <el-pagination @current-change="handleCurrentChange" :page-size="10" :current-page="currentPage"
                layout="total, prev, pager, next, jumper" :total="bookData.total">
            </el-pagination>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        bookData: {
            type: Object,
            default: {
                title: '',
                total: 0,
                bookList: [
                    {
                        title: '',
                        author: '',
                        book: '',
                        time: ''
                    }
                ]
            }
        },
        pageName: {
            type: String,
            default: ''
        }
    },
    watch: {
        bookData(newVal, oldVal) {
            // console.log('6876786', newVal)
            this.currentPage = 1
        }
    },
    data() {
        return {
            currentPage: 1
        }
    },
    methods: {
        handleCurrentChange(val) {
            this.$emit('pageChange', val)
        },
        goDetail (item) {
            // .innerResourceId, item.title
            // console.log('item', item)
            if (item.resourceType == 'COLLECTED_PAPERS') {
                this.$router.push({ path: '/c/c', query: { id: item.id, pN: encodeURI(this.pageName.slice(0, 13)) } })
            } else if ( item.resourceType == 'WENJI' || item.resourceType == 'BOOK') {
                this.$router.push({ path: '/collectionDetail/c', query: { id: item.id, pN: encodeURI(this.pageName) } })
            } else if (item.resourceType == 'ARTICLE') {
                this.$router.push({ path: '/aD/a', query: { id: item.id, pN: encodeURI(this.pageName.slice(0, 13)) } })
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.com-book-component {
    .com-title {
        border-bottom: none;
    }
    ul.com-book {
        margin-top: 10px;
        display: flex;
        flex-wrap: wrap;
        li {
            margin-right: 35px;
            margin-bottom: 52px;
            margin-top: 15px;
            position: relative;
            img {
                display: block;
                width: 200px;
                height: 270px;
                margin-bottom: 16px;
                cursor: pointer;
                box-shadow: 0px 0px 8px 2px rgba(0, 0, 0, 0.18);
                // box-shadow: 0px 3px 9px 0px rgba(15, 11, 5, 0.29);
            }
            p {
                position: absolute;
                left: 50%;
                bottom: -5px;
                transform: translateX(-50%);
                width: 176px;
                line-height: 44px;
                font-size: 14px;
                text-align: center;
                cursor: pointer;
                
                background: #FEE8DB;
                box-shadow: 0px 0px 4px 2px rgba(0, 0, 0, 0.1);
                padding: 0 10px;
                text-align: center;
                a {
                    width: 156px;
                    display: block;
                    font-size: 14px;
                    color: #333333;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                }
            }
        }
        li:nth-child(5n) {
            margin-right: 0;
        }
    }
    .pagination-wrap {
        display: flex;
        justify-content: center;
        margin-bottom: 20px;
    }
}
</style>