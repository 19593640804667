<template>
    <div class="com-article-component">
        <div class="com-title">
            <div>
                <h5>
                    文章
                    <!-- {{articleData.title}} -->
                </h5>
            </div>
            <!-- <div>
                <span>更多<img src="../assets/img/more.png" alt="" /></span>
            </div> -->
        </div>
        <ul class="com-article">
            <li v-for="(item, index) in articleData.articleList" :key="index" @click="goDetail(item)">
                <p><a :title="item.searchTitle ? item.searchTitle : item.title">{{item.searchTitle ? item.searchTitle : item.title}}</a> </p>
                <span v-if="item.author"><a :title="item.author">{{item.author}}</a></span>
                </li>
            <!-- <li v-for="(item, index) in articleData.articleList" :key="index" @click="goDetail(item)">
                <h5>
                    {{item.searchTitle ? item.searchTitle : item.title}}
                </h5>
                <div>
                    <p class="author" v-if="item.author">{{item.author}}</p> -->
            <!-- 所属集刊 -->
            <!-- <p class="name" v-if="item.source">{{item.source}}</p>
                    <p class="time" v-if="item.releaseDate">{{item.releaseDate}}</p>
                </div>
            </li> -->
        </ul>
        <div class="pagination-wrap">
            <el-pagination @current-change="handleCurrentChange" :current-page="currentPage" :page-size="20"
                layout="total, prev, pager, next, jumper" :total="articleData.total">
            </el-pagination>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        articleData: {
            type: Object,
            default: {
                title: '',
                total: 0,
                articleList: [
                    {
                        title: '',
                        author: '',
                        book: '',
                        time: ''
                    }
                ]
            }
        },
        pageName: {
            type: String,
            default: ''
        }
    },
    watch: {
        articleData(newVal, oldVal) {
            // console.log('6876786', newVal)
            this.currentPage = 1
        }
    },
    data() {
        return {
            currentPage: 1
        }
    },
    methods: {
        handleCurrentChange(val) {
            this.$emit('pageChange', val)
        },
        goDetail(item) {
            this.$router.push({ path: '/aD/a', query: { id: item.id, pN: encodeURI(this.pageName.slice(0, 13)) } })
        }
    }
}
</script>

<style lang="scss" scoped>
.com-article-component {
    .com-title {
        border-bottom: none;
    }
    ul.com-article {
        margin-top: 30px; //17
        margin-bottom: 30px;
        display: flex;
        // display: flex;
        flex-wrap: wrap;
        justify-content: space-between;

        li {
            width: 551px;
            box-sizing: border-box;
            line-height: 19px;
            padding-bottom: 12px;
            padding-top: 2px;
            font-size: 16px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            p {
                width: 431px;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                cursor: pointer;
                font-size: 16px;
                color: #333;

            }
            span {
                width: 80px;
                font-size: 14px;
                color: #666;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
            }
            p a, span a {
                width: 100%;
                display: block;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
            }
        }

        li {
            padding-left: 10px;
            position: relative;
        }
        
        li::before {
            content: '';
            width: 4px;
            height: 4px;
            border-radius: 50%;
            background: #ffac13;
            position: absolute;
            left: 0;
            top: 8px;
        }
        

        li:nth-child(2n + 2) {
        }
        li:nth-child(2n + 1) {
            padding-left: 16px;
            position: relative;
        }
        li:nth-child(2n+1)::after {
            content: '';
            position: absolute;
            right: -22px;
            top: 0;
            width: 1px;
            height: 34px;
            background: #e5e5e5;
        }
        li:nth-child(2n + 1)::before {
            left: 6px;
        }
    }
    .pagination-wrap {
        display: flex;
        justify-content: center;
        margin-bottom: 20px;
    }
}
</style>